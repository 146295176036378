import {
  Button,
  DialogOpenChangeData,
  DialogOpenChangeEvent,
  Skeleton,
  SkeletonItem,
  Tooltip,
  makeStyles,
  tokens,
} from '@fluentui/react-components'
import { Checkmark20Regular, Info16Regular } from '@fluentui/react-icons'
import { useTranslation } from 'react-i18next'

import { ResourceGroup } from '../../hooks/usePollResourceGroups'
import { CreateResourceGroupDialog } from './CreateResourceGroupDialog'
import { SelectResourceGroupDialog } from './SelectResourceGroupDialog'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  skeleton: {
    width: '400px',
  },
  descriptionContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: tokens.spacingHorizontalS,
  },
  checkmarkIcon: {
    flexShrink: 0,
  },
})

type ResourceGroupStepProps = {
  loading: boolean
  disabled: boolean
  open: boolean
  resourceGroup?: ResourceGroup
  resourceGroups?: ResourceGroup[]
  onResourceGroupDialogOpenChange: (
    event: DialogOpenChangeEvent,
    data: DialogOpenChangeData
  ) => void
  onSetResourceGroup: (resourceGroup: ResourceGroup) => void
  onCreateResourceGroup: (resourceGroupName: string) => void
}

function ResourceGroupStep({
  loading,
  disabled,
  open,
  resourceGroup,
  resourceGroups,
  onResourceGroupDialogOpenChange,
  onSetResourceGroup,
  onCreateResourceGroup,
}: ResourceGroupStepProps) {
  const styles = useStyles()
  const noResourceGroups = resourceGroups && resourceGroups.length === 0
  const { t } = useTranslation()

  if (disabled || noResourceGroups) {
    return (
      <div className={styles.container}>
        <FolderHeading />
        <CreateResourceGroupDialog
          disabled={disabled}
          onCreateResourceGroup={onCreateResourceGroup}
        />
      </div>
    )
  }

  if (loading) {
    return (
      <div className={styles.container}>
        <FolderHeading />
        <Skeleton className={styles.skeleton} aria-label={t('loading-folders')}>
          <SkeletonItem size={40} appearance="translucent" />
        </Skeleton>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <FolderHeading />
      <div className={styles.descriptionContainer}>
        <SelectResourceGroupDialog
          resourceGroup={resourceGroup}
          onOpenChange={onResourceGroupDialogOpenChange}
          onSetResourceGroup={onSetResourceGroup}
          open={open}
          resourceGroups={resourceGroups as ResourceGroup[]}
        />
        {resourceGroup && (
          <Checkmark20Regular
            className={styles.checkmarkIcon}
            color={tokens.colorBrandForeground1}
            data-testid="resourceGroupCheckmarkIcon"
          />
        )}
      </div>
    </div>
  )
}

function FolderHeading() {
  const { t } = useTranslation()
  return (
    <h4>
      {t('folder')}
      <Tooltip content={t('folder-heading-tooltip')} relationship="label">
        <Button appearance="transparent" icon={<Info16Regular />} />
      </Tooltip>
    </h4>
  )
}

export { ResourceGroupStep }
