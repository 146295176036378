import { SuccessDark } from '@axiscommunications/fluent-illustrations'
import { AuthStatus, useAuth } from '@axteams-one/auth-provider'
import { StorageLocation } from '@axteams-one/bws-cloud-discovery'
import {
  useStorageLocations,
  useWebApplication,
} from '@axteams-one/bws-cloud-discovery/react'
import { useFlag } from '@axteams-one/bws-cloud-flags/react'
import { datadogRum } from '@datadog/browser-rum'
import {
  Button,
  Card,
  CardFooter,
  Dialog,
  DialogBody,
  DialogContent,
  DialogOpenChangeData,
  DialogOpenChangeEvent,
  DialogSurface,
  DialogTitle,
  MessageBar,
  MessageBarActions,
  MessageBarBody,
  Spinner,
  Text,
  Title1,
  Toast,
  ToastBody,
  ToastTitle,
  Toaster,
  makeStyles,
  mergeClasses,
  tokens,
  useId,
  useToastController,
} from '@fluentui/react-components'
import { DismissRegular, SignOut20Regular } from '@fluentui/react-icons'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { animated, useSpring } from 'react-spring'
import { useSessionStorage } from 'usehooks-ts'

import backgroundImageUrl from '../../assets/w100_w800.png'
import { ExternalLink } from '../components/ExternalLink'
import { Illustration } from '../components/Illustration'
import { LoadingPage } from '../components/Loading'
import { AuthCodeStep } from '../components/onboarding/AuthCodeStep'
import {
  Organization,
  OrganizationStep,
} from '../components/onboarding/OrganizationStep'
import { ResourceGroupStep } from '../components/onboarding/ResourceGroupStep'
import { StorageLocationStep } from '../components/onboarding/StorageLocationStep'
import { config } from '../config'
import { AUTH_CODE_LENGTH } from '../constants'
import { ErrorCode } from '../graphql/__generated__/graphql'
import { ApiStatus } from '../hooks/types'
import { useActivateApplication } from '../hooks/useActivateApplication'
import { useClaimSystem } from '../hooks/useClaimSystem'
import { useCreateAccess } from '../hooks/useCreateAccess'
import { useCreateOrganization } from '../hooks/useCreateOrganization'
import { useCreateResourceGroup } from '../hooks/useCreateResourceGroup'
import { useOrganizationPrincipal } from '../hooks/useOrganizationPrincipal'
import { usePollOrganizations } from '../hooks/usePollOrganizations'
import {
  ResourceGroup,
  usePollResourceGroups,
} from '../hooks/usePollResourceGroups'
import { useRedirectIfNotLoggedIn } from '../hooks/useRedirectIfNotLoggedIn'
import { useStartTrial } from '../hooks/useStartTrial'
import { Api, useUrqlClient } from '../hooks/useUrqlClient'
import i18n from '../i18n/i18n'
import { stringToLanguageCode } from '../i18n/language'
import { Flags } from '../util/flags'
import './reset.css'

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%',
    backgroundImage: `url(${backgroundImageUrl})`,
    backgroundSize: 'cover',
    paddingBottom: tokens.spacingVerticalM,
  },
  title: {
    margin: tokens.spacingVerticalXL,
  },
  card: {
    margin: tokens.spacingVerticalXL,
    paddingTop: tokens.spacingVerticalXL,
    rowGap: tokens.spacingVerticalXXL,
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '40em',
    overflow: 'visible',
  },
  finishedCard: {
    '@media (min-width: 750px)': {
      maxWidth: '700px',
    },
    alignItems: 'center',
  },
  finishedTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardContent: {
    padding: `0 calc(${tokens.spacingHorizontalXL} * 2.5) ${tokens.spacingVerticalXXL} `,
    width: '40em',
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalM,
  },
  messageBox: { padding: `${tokens.spacingVerticalS} 10px` },
  details: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: `${tokens.spacingVerticalM} ${tokens.spacingHorizontalXXXL}`,
    marginTop: tokens.spacingVerticalM,
  },
  closeTabText: {
    marginTop: tokens.spacingVerticalM,
    marginBottom: tokens.spacingVerticalM,
  },
  bold: {
    fontWeight: 'bold',
  },
})

const ORGANIZATION_POLL_INTERVAL = 5000
const RESOURCE_GROUPS_POLL_INTERVAL = 5000
const TOAST_TIMEOUT_SUCCESS = 15000
const TOAST_TIMEOUT_ERROR = 5000
const TOAST_TIMEOUT_BAD_AUTH_CODE = 8000
const TOASTER_DELAY = 2000

function ClaimSystemPage() {
  // TODO When this flag is removed, move this so that it's run once
  // globally and not on every render.
  const enableDataDogRum = useFlag(Flags.ENABLE_DATADOG_RUM)?.enabled === true
  if (enableDataDogRum) {
    initDataDog()
  }

  const styles = useStyles()
  const { logout, status } = useAuth()
  useRedirectIfNotLoggedIn()
  const iam = useWebApplication('iam-portal')
  const storageLocations = useStorageLocations()
  const toasterId = useId('toaster')
  const { dispatchToast, dismissToast } = useToastController(toasterId)
  const createOrganization = useCreateOrganization()
  const createAccess = useCreateAccess()
  const activateApplication = useActivateApplication()
  const organizationPrincipal = useOrganizationPrincipal()
  const [isCreatingOrganization, setIsCreatingOrganization] = useState(false)
  const [createdOrganizations, setCreatedOrganizations] = useState<string[]>([])
  const createResourceGroup = useCreateResourceGroup()
  const [isCreatingResourceGroup, setIsCreatingResourceGroup] = useState(false)
  const claimSystem = useClaimSystem()
  const startTrial = useStartTrial()
  const [finished, setFinished] = useState<boolean>(false)
  const [organization, setOrganization] = useSessionStorage<
    Organization | undefined
  >('current:organization', undefined)
  const [resourceGroup, setResourceGroup] = useSessionStorage<
    ResourceGroup | undefined
  >('current:resourcegroup', undefined)
  const [authCode, setAuthCode] = useState<string>()

  const [storageLocation, setStorageLocation] = useState<StorageLocation>()
  const [isClaiming, setIsClaiming] = useState(false)
  const [urlSearchParams] = useSearchParams()
  const systemId = urlSearchParams.get('system')
  const language = urlSearchParams.get('lang')
  const bwoUrqlClient = useUrqlClient(Api.Bwo)
  const [pauseOrganizationPolling, setPauseOrganizationPolling] = useState(true)
  const [organizations, reexecuteGetOrganizations] = usePollOrganizations({
    client: useUrqlClient(Api.Acx),
    paused: pauseOrganizationPolling,
    pollInterval: ORGANIZATION_POLL_INTERVAL,
  })
  const [isResourceGroupDialogOpen, setIsResourceGroupDialogOpen] =
    useState(false)
  const [resourceGroupsQuery, refetchResourceGroups] = usePollResourceGroups({
    client: useUrqlClient(Api.Acx),
    organizationId: organization?.id,
    paused: isResourceGroupDialogOpen === false, // Pause until we open the dialog
    pollInterval: RESOURCE_GROUPS_POLL_INTERVAL,
  })
  const [resourceGroups, setResourceGroups] = useState<ResourceGroup[]>()
  const [showMessageBar, setShowMessageBar] = useState(false)

  // TODO: way to change language
  useEffect(() => {
    i18n.changeLanguage(stringToLanguageCode(language ?? 'en'))
  }, [language])

  // Changing the zIndex to -1 when messageBar is hidden is a temporary
  // fix to prevent the messageBar from blocking the organizations tooltip.
  // Will be solved in a better way later, when we replace the animation package
  const messageBarAnimation = useSpring(
    showMessageBar
      ? {
          zIndex: 0,
          opacity: 1,
          height: '40px',
          config: { tension: 800, clamp: true, duration: 200 },
        }
      : {
          zIndex: -1,
          opacity: 0,
          height: '0',
          config: { tension: 800, clamp: true, duration: 200 },
        }
  )

  useEffect(() => {
    if (!organization) {
      return
    }

    const organizationIsCreated = createdOrganizations.includes(organization.id)

    setShowMessageBar(!organizationIsCreated)
  }, [createdOrganizations, organization])

  useEffect(() => {
    // Reset state belonging to the old organization
    setResourceGroup(undefined)
    setResourceGroups(undefined)
  }, [organization?.id, setResourceGroup])

  useEffect(() => {
    if (resourceGroupsQuery.status === ApiStatus.Resolved) {
      setResourceGroups(resourceGroupsQuery.data)
    }
  }, [resourceGroupsQuery])

  // Set chosen resource group to first one in the list if none has been cached
  // NOTE that we probably need to check if our resource group dialog is open
  // later because we will start polling and changing the resource group.
  useEffect(() => {
    if (resourceGroups && resourceGroups.length > 0 && !resourceGroup) {
      setResourceGroup(resourceGroups[0])
    }
  }, [resourceGroup, resourceGroups, setResourceGroup])

  const { t } = useTranslation()
  if (!systemId) {
    return <NoSystemAlertDialog />
  }

  if (status === AuthStatus.Uninitialized || !organizations) {
    return <LoadingPage />
  }

  if (finished) {
    return (
      <>
        <Toaster
          toasterId={toasterId}
          position="top-end"
          offset={{ vertical: 48 }}
          limit={5}
          mountNode={document.fullscreenElement}
        />
        <div id="page" className={styles.page}>
          <Card
            size="large"
            className={mergeClasses(styles.card, styles.finishedCard)}
            data-testid="finishCard"
          >
            <Title1 className={styles.title} as="h1">
              {t('system-is-connected')}
            </Title1>
            <Illustration illustration={SuccessDark} />
            <div className={styles.details}>
              <div>{t('organization')}:</div>
              <div className={styles.bold}>{organization?.name}</div>
              <div>{t('organization')}:</div>
              <div className={styles.bold}>{resourceGroup?.name}</div>
            </div>
            <Text align="center" className={styles.closeTabText}>
              {t('you-can-close-the-tab')}
            </Text>
          </Card>
        </div>
      </>
    )
  }

  const loadingOrganizations = !organizations || isCreatingOrganization
  const loadingResourceGroups = !resourceGroups || isCreatingResourceGroup
  const disableResourceGroupStep =
    loadingOrganizations || !organization || organization.id.trim() === ''
  const disableFinish =
    disableResourceGroupStep ||
    !resourceGroup ||
    resourceGroup.id.trim() === '' ||
    !authCode ||
    authCode.length !== AUTH_CODE_LENGTH ||
    isClaiming ||
    !storageLocation

  const dismissMessageButton = (
    <Button
      appearance="transparent"
      icon={<DismissRegular />}
      onClick={() => setShowMessageBar(false)}
      data-testid="messageBoxClose"
    />
  )

  const finishButton = (
    <Button
      disabled={disableFinish}
      onClick={handleFinish}
      data-testid="finishButton"
    >
      {t('action--finish')}
    </Button>
  )

  let iamUrl = ''
  // In case no organization is selected, the link should still work,
  // but without linking to a specific organization
  if (iam) {
    iamUrl = getPopulatedUrl(iam.uri, organization?.id ?? '', language ?? 'en')
  }
  const iamUrlLink = (
    <ExternalLink
      text="AXIS User Management"
      href={iamUrl}
      data-testid="iamLink"
    />
  )

  return (
    <>
      <Toaster
        toasterId={toasterId}
        position="top-end"
        offset={{ vertical: 48 }}
        limit={5}
        mountNode={document.fullscreenElement}
      />
      <div id="page" className={styles.page}>
        <Title1 className={styles.title} as="h1">
          {t('connect-to-an-organization')}
        </Title1>
        <Card size="large" className={styles.card}>
          <animated.div style={messageBarAnimation} data-testid="messageBox">
            <MessageBar intent="warning" className={styles.messageBox}>
              <MessageBarBody>
                {t('warn-about-admin-role')} <p>{iamUrlLink}</p>
              </MessageBarBody>
              <MessageBarActions containerAction={dismissMessageButton} />
            </MessageBar>
          </animated.div>
          <div className={styles.cardContent}>
            <OrganizationStep
              loading={loadingOrganizations}
              organization={organization}
              organizations={organizations}
              onSetOrganization={handleSetOrganization}
              onCreateOrganization={handleCreateOrganization}
              setPauseOrganizationPolling={setPauseOrganizationPolling}
              showWarning={showMessageBar}
            />
            <ResourceGroupStep
              loading={loadingResourceGroups}
              disabled={disableResourceGroupStep}
              resourceGroup={isCreatingOrganization ? undefined : resourceGroup}
              onResourceGroupDialogOpenChange={
                handleResourceGroupDialogOpenChange
              }
              onSetResourceGroup={handleSetResourceGroup}
              onCreateResourceGroup={(resourceGroupName: string) =>
                handleCreateResourceGroup(
                  organization?.id ?? '',
                  resourceGroupName
                )
              }
              open={isResourceGroupDialogOpen}
              resourceGroups={resourceGroups}
            />
            <StorageLocationStep
              storageLocation={storageLocation}
              storageLocations={storageLocations}
              onSetStorageLocation={handleSetStorageLocation}
            />
            <AuthCodeStep
              authCode={authCode}
              onSetAuthCode={handleSetAuthCode}
            />
          </div>
          <CardFooter action={finishButton} />
        </Card>
        <Button
          appearance="transparent"
          icon={<SignOut20Regular />}
          onClick={handleLogoutClick}
        >
          {t('action--logout')}
        </Button>
      </div>
    </>
  )

  function handleLogoutClick() {
    // In BWO there's no proper landing page. So if we were to log out with
    // redirect as usual here, the user would end up on a blank page. Instead,
    // reuse the login redirect behavior to redirect user back to the current
    // page.
    if (window.location.href) {
      const route = window.location.href.replace(window.location.origin, '')
      window.localStorage.setItem('auth:redirect:url', route)
    }
    logout()
  }

  function handleSetOrganization(organization: Organization) {
    setOrganization((previousOrganization) => {
      if (organization.id !== previousOrganization?.id) {
        return organization
      }

      return previousOrganization
    })
  }

  function handleResourceGroupDialogOpenChange(
    _event: DialogOpenChangeEvent,
    data: DialogOpenChangeData
  ) {
    setIsResourceGroupDialogOpen(data.open)
  }

  function handleSetResourceGroup(resourceGroup: ResourceGroup) {
    setResourceGroup(resourceGroup)
  }

  function handleSetStorageLocation(storageLocation: StorageLocation) {
    setStorageLocation(storageLocation)
  }

  function handleSetAuthCode(authCode: string) {
    setAuthCode(authCode)
  }

  async function handleFinish() {
    // TODO use same method for enabling finish button
    if (
      !authCode ||
      !resourceGroup ||
      !bwoUrqlClient ||
      !systemId ||
      !organization ||
      !storageLocation
    ) {
      return
    }

    // Activate Body Worn Live for this organization. This is done before claim
    // so that we don't get into the situation where activation fails even
    // though claim was successful. This way the user can reload and hopefully
    // everything goes through.
    const organizationArn = `arn:organization:${organization.id}`
    const activation = await activateApplication({
      applicationArn: config.applicationArn,
      organizationArn,
    })
    if (activation?.status === ApiStatus.Rejected) {
      dispatchToast(<ClaimSystemGeneralFailureToast />, {
        intent: 'error',
        timeout: TOAST_TIMEOUT_ERROR,
      })
      return
    }

    const claimSystemToastTimeout = setTimeout(() => {
      dispatchToast(<ClaimSystemProgressToast />, { toastId: 'progress-toast' })
      clearTimeout(claimSystemToastTimeout)
    }, TOASTER_DELAY)

    // Prevent multiple clicks on finish
    setIsClaiming(true)

    const claim = await claimSystem({
      authorizationCode: authCode,
      resourceParent: resourceGroup.arn,
      systemId,
      storageLocationId: storageLocation.id,
    })

    clearTimeout(claimSystemToastTimeout)
    dismissToast('progress-toast')

    if (claim?.status === ApiStatus.Rejected) {
      setIsClaiming(false)
      if (claim.error === ErrorCode.BwoUnauthorized) {
        setShowMessageBar(true)
        dispatchToast(
          <ClaimSystemUnauthorizedToast
            resourceGroupName={resourceGroup.name}
          />,
          { intent: 'error', timeout: TOAST_TIMEOUT_ERROR }
        )
      } else if (claim.error === ErrorCode.BwoClaimUnauthorized) {
        dispatchToast(<ClaimSystemBadAuthcodeToast />, {
          intent: 'error',
          timeout: TOAST_TIMEOUT_BAD_AUTH_CODE,
        })
      } else if (claim.error === ErrorCode.BwoSystemAlreadyClaimed) {
        dispatchToast(<ClaimSystemAlreadyClaimedToast />, {
          intent: 'error',
          timeout: TOAST_TIMEOUT_ERROR,
        })
      } else {
        dispatchToast(<ClaimSystemGeneralFailureToast />, {
          intent: 'error',
          timeout: TOAST_TIMEOUT_ERROR,
        })
      }
    } else if (claim?.status === ApiStatus.Resolved) {
      const trial = await startTrial(organization.id)
      setIsClaiming(false)
      setFinished(true)

      if (trial?.status === ApiStatus.Rejected) {
        if (trial.error === ErrorCode.BwoTrialAlreadyStarted) {
          return
        }

        dispatchToast(<StartTrialFailureToast />, {
          intent: 'error',
          timeout: TOAST_TIMEOUT_ERROR,
        })
        return
      } else if (trial?.status === ApiStatus.Resolved) {
        dispatchToast(<ClaimSystemSuccessToast />, {
          intent: 'success',
          timeout: TOAST_TIMEOUT_SUCCESS,
        })
      }
    }
  }

  async function handleCreateOrganization(organizationName: string) {
    const createOrganizationToastTimeout = setTimeout(() => {
      dispatchToast(<OrganizationCreateProgressToast />, {
        toastId: 'progress-toast',
      })
      clearTimeout(createOrganizationToastTimeout)
    }, TOASTER_DELAY)

    setIsCreatingOrganization(true)

    const create = await createOrganization({
      name: organizationName,
    })

    clearTimeout(createOrganizationToastTimeout)
    dismissToast('progress-toast')
    if (create?.status === ApiStatus.Rejected) {
      setIsCreatingOrganization(false)
      dispatchToast(<OrganizationCreateFailedToast />, {
        intent: 'error',
        timeout: TOAST_TIMEOUT_ERROR,
      })
    } else if (create?.status === ApiStatus.Resolved) {
      setCreatedOrganizations((prev) => [...prev, organizationId])
      const assignRolesToastTimeout = setTimeout(() => {
        dispatchToast(<AssigningRolesProgressToast />, {
          toastId: 'progress-toast',
        })
        clearTimeout(assignRolesToastTimeout)
      }, TOASTER_DELAY)

      const organizationArn = create.data?.createOrganization
        ?.organizationArn as string
      const organizationId = organizationArn.split(/:/)[2]
      reexecuteGetOrganizations()

      setOrganization({
        id: organizationId,
        name: organizationName,
      })

      const principal = await organizationPrincipal({ organizationId })

      if (principal?.status === ApiStatus.Rejected) {
        setIsCreatingOrganization(false)
        clearTimeout(assignRolesToastTimeout)
        dismissToast('progress-toast')
        dispatchToast(<AssigningRolesFailedToast />, {
          intent: 'error',
          timeout: TOAST_TIMEOUT_ERROR,
        })

        return
      } else if (principal?.status === ApiStatus.Resolved) {
        const principalArn =
          (principal.data?.organization?.self?.principal?.arn as string) ?? ''

        // Assign Admin role.
        const access = await createAccess({
          principalArn,
          roleId: config.globalAdminRoleId,
          targetArn: organizationArn ?? '',
        })

        if (access?.status === ApiStatus.Rejected) {
          setIsCreatingOrganization(false)
          clearTimeout(assignRolesToastTimeout)
          dismissToast('progress-toast')
          dispatchToast(<AssigningRolesFailedToast />, {
            intent: 'error',
            timeout: TOAST_TIMEOUT_ERROR,
          })
          return
        }

        clearTimeout(assignRolesToastTimeout)
        dismissToast('progress-toast')

        // Create default resource group
        handleCreateResourceGroup(organizationId, 'default').then(() => {
          // Finish up
          setIsCreatingOrganization(false)
          dispatchToast(<OrganizationCreateSuccessToast />, {
            intent: 'success',
            timeout: TOAST_TIMEOUT_SUCCESS,
          })
        })
      }
    }
  }

  async function handleCreateResourceGroup(
    organizationId: string,
    resourceGroupName: string
  ) {
    const createResourceGroupTimeoutId = setTimeout(function delayToast() {
      dispatchToast(<CreateResourceGroupProgressToast />, {
        toastId: 'progress-toast',
      })
      clearTimeout(createResourceGroupTimeoutId)
    }, TOASTER_DELAY)

    setIsCreatingResourceGroup(true)

    const createResourceGroupResult = await createResourceGroup({
      description: `${resourceGroupName} folder`,
      name: resourceGroupName,
      parentArn: `arn:organization:${organizationId}`,
    })

    clearTimeout(createResourceGroupTimeoutId)

    if (createResourceGroupResult?.status === ApiStatus.Rejected) {
      dispatchToast(<CreateResourceGroupFailedToast />, { intent: 'error' })
    } else if (createResourceGroupResult?.status === ApiStatus.Resolved) {
      refetchResourceGroups(organizationId)

      const resourceGroupArn = createResourceGroupResult.data
        ?.createResourceGroup?.resourceGroupArn as string
      const resourceGroupId = resourceGroupArn.split(/:/)[2].split('/')[1]
      const resourceGroup = {
        id: resourceGroupId,
        name: resourceGroupName,
        arn: resourceGroupArn,
        parent: organizationId,
      }

      setResourceGroup(resourceGroup)
      setResourceGroups([resourceGroup])
    }

    setIsCreatingResourceGroup(false)
  }
}

function OrganizationCreateProgressToast() {
  const { t } = useTranslation()
  return (
    <Toast>
      <ToastTitle media={<Spinner size="tiny" />}>
        {t('creating-organization')}
      </ToastTitle>
    </Toast>
  )
}

function OrganizationCreateSuccessToast() {
  const { t } = useTranslation()
  return (
    <Toast data-testid="organizationCreateSuccessToast">
      <ToastTitle>{t('successfully-created-organization')}</ToastTitle>
    </Toast>
  )
}

function OrganizationCreateFailedToast() {
  const { t } = useTranslation()
  return (
    <Toast>
      <ToastTitle>{t('couldnt-create-organization')}</ToastTitle>
      <ToastBody>{t('try-again-later')}</ToastBody>
    </Toast>
  )
}

function AssigningRolesProgressToast() {
  const { t } = useTranslation()
  return (
    <Toast>
      <ToastTitle media={<Spinner size="tiny" />}>
        {t('assigning-admin-role')}
      </ToastTitle>
    </Toast>
  )
}

function AssigningRolesFailedToast() {
  const { t } = useTranslation()
  return (
    <Toast>
      <ToastTitle>{t('couldnt-assign-role')}</ToastTitle>
      <ToastBody>{t('try-again-later')}</ToastBody>
    </Toast>
  )
}

function CreateResourceGroupProgressToast() {
  const { t } = useTranslation()
  return (
    <Toast>
      <ToastTitle media={<Spinner size="tiny" />}>
        {t('creating-folder')}
      </ToastTitle>
    </Toast>
  )
}

function CreateResourceGroupFailedToast() {
  const { t } = useTranslation()
  return (
    <Toast>
      <ToastTitle>{t('couldnt-create-folder')}</ToastTitle>
      <ToastBody>{t('try-again-later')}</ToastBody>
    </Toast>
  )
}

function ClaimSystemProgressToast() {
  const { t } = useTranslation()
  return (
    <Toast>
      <ToastTitle media={<Spinner size="tiny" />}>
        {t('connecting-system')}
      </ToastTitle>
    </Toast>
  )
}

function ClaimSystemUnauthorizedToast({
  resourceGroupName,
}: {
  resourceGroupName: string
}) {
  const { t } = useTranslation()
  return (
    <Toast>
      <ToastTitle>{t('insufficient-permissions')}</ToastTitle>
      <ToastBody>
        <Trans
          t={t}
          i18nKey={'claim-system-unauthorized'}
          values={{ resourceGroupName }}
        />
      </ToastBody>
    </Toast>
  )
}

function ClaimSystemBadAuthcodeToast() {
  const { t } = useTranslation()
  return (
    <Toast>
      <ToastTitle>{t('invalid-auth-code')}</ToastTitle>
      <ToastBody>{t('verify-code-or-copy')}</ToastBody>
    </Toast>
  )
}

function ClaimSystemAlreadyClaimedToast() {
  const { t } = useTranslation()
  return (
    <Toast>
      <ToastTitle>{t('system-already-claimed')}</ToastTitle>
      <ToastBody>{t('disconnect-system-before-new-connection')}</ToastBody>
    </Toast>
  )
}

function ClaimSystemGeneralFailureToast() {
  const { t } = useTranslation()
  return (
    <Toast>
      <ToastTitle>{t('failed-to-connect-system')}</ToastTitle>
      <ToastBody>{t('try-again-later')}</ToastBody>
    </Toast>
  )
}

function StartTrialFailureToast() {
  const { t } = useTranslation()
  return (
    <Toast>
      <ToastTitle>{t('failed-to-start-trial')}</ToastTitle>
      <ToastBody>{t('couldnt-start-trial')}</ToastBody>
    </Toast>
  )
}

function ClaimSystemSuccessToast() {
  const { t } = useTranslation()
  return (
    <Toast data-testid="organizationClaimSuccessToast">
      <ToastTitle>{t('successfully-connected-the-system')}</ToastTitle>
    </Toast>
  )
}

function NoSystemAlertDialog() {
  const { t } = useTranslation()
  return (
    <Dialog modalType="alert" open={true}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{t('system-id-missing')}</DialogTitle>
          <DialogContent>{t('system-id-missing-information')}</DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}

/** Initialize DataDog Rum Browser Monitoring to track user interactions. */
function initDataDog() {
  if (!datadogRum.getInitConfiguration()) {
    datadogRum.init({
      applicationId: '1b858622-d669-498b-8bc9-dfbfdbeaf4ef',
      clientToken: 'pub1eff92283a1a7f6e5d66ec2576beb934',
      site: 'datadoghq.eu',
      service: 'axis-body-worn-online',
      env: config.environment,
      version: import.meta.env.VITE_APP_VERSION || 'dev_build',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      trackUserInteractions: false,
      trackResources: false,
      trackLongTasks: false,
      defaultPrivacyLevel: 'mask',
      enablePrivacyForActionName: true,
      silentMultipleInit: true,
    })

    datadogRum.setGlobalContext({
      axisinstance: config.environment,
      team: 'bodyworn',
      owner: 'bodyworn',
    })
  }
}

function getPopulatedUrl(
  urlString: string,
  orgId: string,
  lang: string
): string {
  const url = new URL(urlString)

  url.searchParams.append('lang', lang)
  url.searchParams.append('org', `arn:organization:${orgId}::`)

  return url.toString()
}

export { ClaimSystemPage }
