import {
  Body1,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogOpenChangeData,
  DialogOpenChangeEvent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Label,
  Radio,
  RadioGroup,
  RadioGroupOnChangeData,
  makeStyles,
  tokens,
  useId,
} from '@fluentui/react-components'
import { Folder24Regular } from '@fluentui/react-icons'
import { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ResourceGroup } from '../../hooks/usePollResourceGroups'
import { Tree, TreeNodeData } from '../tree'

const useStyles = makeStyles({
  dialogTriggerButton: {
    width: '400px',
  },
  dialogTriggerButtonText: {
    width: '100%',
    justifySelf: 'start',
  },
  dialogBody: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalL,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalM,
  },
  dialogActions: {
    justifyContent: 'end',
  },
  radioGroup: {
    display: 'flex',
    overflowY: 'auto',
  },
})

type ResourceGroupDialogProps = {
  resourceGroup?: ResourceGroup
  onOpenChange: (
    event: DialogOpenChangeEvent,
    data: DialogOpenChangeData
  ) => void
  onSetResourceGroup: (resourceGroup: ResourceGroup) => void
  open: boolean
  resourceGroups: ResourceGroup[]
}

type ResourceGroupsSectionProps = {
  resourceGroups: ResourceGroup[]
  selectedResourceGroup: ResourceGroup | undefined
  setSelectedResourceGroup: React.Dispatch<
    React.SetStateAction<ResourceGroup | undefined>
  >
}

export function SelectResourceGroupDialog({
  resourceGroup,
  onSetResourceGroup,
  onOpenChange,
  open,
  resourceGroups,
}: ResourceGroupDialogProps) {
  const styles = useStyles()
  const [selectedResourceGroup, setSelectedResourceGroup] = useState<
    ResourceGroup | undefined
  >(resourceGroup)
  const { t } = useTranslation()

  useEffect(() => {
    setSelectedResourceGroup(resourceGroup)
  }, [resourceGroup])

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger disableButtonEnhancement>
        <Button
          size="large"
          className={styles.dialogTriggerButton}
          appearance="secondary"
          icon={<Folder24Regular color={tokens.colorNeutralStrokeAccessible} />}
        >
          <Body1
            className={styles.dialogTriggerButtonText}
            data-testid="selectResourceGroupDialogText"
          >
            {resourceGroup?.name}
          </Body1>
        </Button>
      </DialogTrigger>
      <DialogSurface>
        <DialogBody className={styles.dialogBody}>
          <DialogTitle>{t('select-folder')}</DialogTitle>
          <DialogContent className={styles.dialogContent}>
            <ResourceGroupsSection
              resourceGroups={resourceGroups}
              selectedResourceGroup={selectedResourceGroup}
              setSelectedResourceGroup={setSelectedResourceGroup}
            />
          </DialogContent>
          <DialogActions className={styles.dialogActions}>
            <DialogTrigger disableButtonEnhancement>
              <Button
                appearance="secondary"
                data-testid="selectResourceGroupDialogCancel"
              >
                {t('action--cancel')}
              </Button>
            </DialogTrigger>
            <DialogTrigger disableButtonEnhancement>
              <Button
                appearance="primary"
                onClick={() =>
                  selectedResourceGroup &&
                  onSetResourceGroup(selectedResourceGroup)
                }
                disabled={!resourceGroup || !selectedResourceGroup}
              >
                {t('action--select')}
              </Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}

function ResourceGroupsSection({
  resourceGroups,
  selectedResourceGroup,
  setSelectedResourceGroup,
}: ResourceGroupsSectionProps) {
  const styles = useStyles()
  const inputId = useId('resourcegroup')
  const { t } = useTranslation()
  const resourceGroupTree = makeTree(resourceGroups)

  return (
    <>
      <Label htmlFor={'organization-input'}>{t('select-a-folder')}</Label>
      <RadioGroup
        value={selectedResourceGroup?.id}
        id={inputId}
        className={styles.radioGroup}
        name="resourceGroup"
        onChange={handleChange}
      >
        <Tree data={resourceGroupTree} onRenderNode={renderResourceGroup} />
      </RadioGroup>
    </>
  )

  function renderResourceGroup(node: TreeNodeData) {
    return (
      <Radio
        key={node.id}
        label={node.title}
        value={node.id}
        aria-label={node.title}
      />
    )
  }

  function handleChange(
    event: FormEvent<HTMLDivElement>,
    data: RadioGroupOnChangeData
  ) {
    setSelectedResourceGroup({
      id: data.value,
      arn: resourceGroups.find(({ id }) => id === data.value)?.arn || '',
      name: (event.target as HTMLDivElement).getAttribute('aria-label') ?? '',
      parent: '',
    })
  }
}

/**
 * Creates a tree of TreeNodeData from a list of resource groups, which can be
 * sent to the AXIS custom tree component.
 *
 * TODO We will be changing into using the Fluent tree component in the future.
 */
function makeTree(resourceGroups: ResourceGroup[]) {
  const tree: TreeNodeData[] = []

  // Convert the resource groups into a list of TreeNodeData which we can
  // iterate and move children into their parent.
  const nodes = resourceGroups.map((resourceGroup) => {
    return {
      id: resourceGroup.id,
      title: resourceGroup.name,
      children: [],
    } as TreeNodeData
  })

  nodes.forEach((node) => {
    const resourceGroup = resourceGroups.find(
      (resourceGroup) => resourceGroup.id == node.id
    )
    if (!resourceGroup) {
      return
    }
    const parent = nodes.find((node) => node.id === resourceGroup.parent)
    if (parent) {
      parent.children?.push(node)
    } else {
      tree.push(node)
    }
  })

  return tree
}
